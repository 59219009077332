export class ObjectsHelper {
  static EnrichQuestions(questions) {
    questions?.forEach((question) => {
      // if (question.ExplanationImg) {
      //   let found = question.ExplanationImg.match(regex);
      //   if (found) {
      //     let jpegArray = Array.from(found, (x) => `${url}/${x}.jpg`);
      //     let pngArray = Array.from(found, (x) => `${url}/${x}.png`);
      //     Object.assign(question, {
      //       JpegArray: jpegArray,
      //       PngArray: pngArray,
      //     });
      //   }
      // }
      let questionTextHtml = `${
        question.TestId
      }) ${question.QuestionText.replace('\n', '')
        .replace('\n\n\n\n\n\n', '')
        .replaceAll('\n', '<br/>')}`;
      let explanationHtml = question.Explanation
        ? question.Explanation.replace('\n', '').replaceAll('\n', '<br/>')
        : '';
      Object.assign(question, {
        IsCorrect: false,
        IsAnswered: false,
        WrongAnswer: null,
        QuestionTextHtml: questionTextHtml,
        ExplanationHtml: explanationHtml,
      });
    });
  }

  static EnrichAreas(areas) {
    // const areasDone = [
    //   'Air Law',
    //   'Communications',
    //   'Human Performance &Limitations',
    //   'Airframe, Systems, Electrics, Power Plant',
    // ];
    // areas?.forEach((area) => {
    //   if (areasDone.indexOf(area.TestAreaName) !== -1)
    //     area.TestAreaName = `d - ${area.TestAreaName}`;
    // });
  }
}
