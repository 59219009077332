import React, { Component } from 'react';
import * as C from '../../../Constants';
import { ObjectsHelper } from '../../../helpers/ObjectsHelper';

class AreaSelector extends Component {
  state = { isAreasLoaded: false, isStatsLoaded: false };
  componentDidMount() {
    this.fetchAreas();
  }
  fetchAreas = () => {
    fetch(`${C.API}/med/areas`)
      .then((res) => res.json())
      .then(
        (result) => {
          if (!result || result.Message) {
            this.setState({ is404: true });
            return;
          }
          ObjectsHelper.EnrichAreas(result);
          let areaId = result.length === 1 ? result[0].Id : 0;
          this.setState({
            areaId: areaId,
            isAreasLoaded: true,
            areas: result,
          });
          if (areaId) this.fetchStats(areaId);
        },
        (error) => {
          console.log('error');
          this.setState({ isAreasLoaded: true, error });
        }
      );
  };
  fetchStats = (areaId) => {
    fetch(`${C.API}/med/stats?testAreaId=${areaId}`)
      .then((res) => res.json())
      .then(
        (result) => {
          if (!result || result.Message) {
            this.setState({ is404: true });
            return;
          }
          this.setState({
            isStatsLoaded: true,
            totalQuestions: result.totalQuestions,
            totalCorrect: result.totalCorrect,
          });
        },
        (error) => {
          console.log('error');
          this.setState({ error });
        }
      );
  };
  render() {
    return (
      <React.Fragment>
        {this.state.isAreasLoaded ? (
          <div className='container px-0'>
            <div className='row'>
              <div className='col-9 px-0'>
                <select
                  className='custom-select font-sm'
                  onChange={this.handleSelection}
                  value={this.state.areaId}>
                  {/* <option
                    key={0}
                    value={0}
                    defaultValue={0 === this.state.areaId}>
                    ----- choose area -----
                  </option> */}
                  {this.state.areas.map((area) => (
                    <option
                      key={area.Id}
                      value={area.Id}
                      defaultValue={area.Id === this.state.areaId}>
                      {area.TestAreaName}
                    </option>
                  ))}
                </select>
              </div>
              <div className='col-3 font-sm px-0 mt-1'>
                {this.state.isStatsLoaded ? (
                  <p className='m-0 text-start'>
                    {this.state.totalCorrect + this.props.correct} /{' '}
                    {this.state.totalQuestions}{' '}
                    {(
                      ((this.state.totalCorrect + this.props.correct) /
                        this.state.totalQuestions) *
                      100
                    ).toFixed(0)}
                    %
                  </p>
                ) : null}
              </div>
            </div>
          </div>
        ) : (
          <div className='mx-3 mt-2 mb-1'>Loading...</div>
        )}
      </React.Fragment>
    );
  }
  handleSelection = (event) => {
    this.setState({ isStatsLoaded: false });
    const areaId = event.target.value;
    this.fetchStats(areaId);
    this.props.onAreaSelection(areaId);
  };
}

export default AreaSelector;
